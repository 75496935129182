import { FetchError } from '..';
import {
    COOKIE_ACCESS_TOKEN,
    COOKIE_REFRESH_TOKEN,
    COOKIE_REFRESH_TOKEN_URL,
    COOKIE_X_API_KEY,
    CUSTOM_EVENT_SESSION_REFRESH_ERROR
} from '../../components/constants';
import { emitEvent } from '../../events';
import { getCookie, isBrowser, setCookie } from '../../utils';
import { BEARER_PREFIX, RefreshTokenResponse, getBaseHeaders, handleFetchError, refreshAccessToken } from './utils';

export const customFetch = async (url: string, options: RequestInit = {}): Promise<any> => {
    if (!isBrowser()) {
        return;
    }

    const refreshTokenUrl = getCookie(COOKIE_REFRESH_TOKEN_URL);
    const accessToken = getCookie(COOKIE_ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIE_REFRESH_TOKEN) ?? '';
    const xApiKey = getCookie(COOKIE_X_API_KEY);

    if (!accessToken && !refreshToken && !xApiKey) {
        throw new FetchError('Required cookies are missing');
    }

    const { headers: baseHeaders, shouldRefreshToken } = getBaseHeaders();

    let headers = {
        ...baseHeaders,
        ...options.headers
    };

    let fetchOptions: any = {
        ...options,
        headers
    };

    try {
        let response = await fetch(url, fetchOptions);

        if (response.ok) {
            if (response.status === 204) {
                return { success: true };
            }
            return await response.json();
        }

        if (shouldRefreshToken && (response.status === 401 || response.status === 403)) {
            const newToken = await refreshAccessToken(refreshTokenUrl!, accessToken!, refreshToken);

            headers = {
                ...headers,
                Authorization: `${BEARER_PREFIX}${newToken?.accessToken}`
            };

            fetchOptions = {
                ...fetchOptions,
                headers
            };

            response = await fetch(url, fetchOptions);

            if (response.ok) {
                if (response.status === 204) {
                    return { success: true };
                }
                return await response.json();
            } else {
                emitEvent('sessionExpired', { message: 'Session has expired' });
                throw new FetchError(`HTTP error! status: ${response.status}`, response.status);
            }
        } else {
            const isResumeOrder400Error: boolean = url.includes('resume') && fetchOptions.method === 'POST' && response.status === 400;
            if (isResumeOrder400Error) {
                return await response.json();
            }

            emitEvent('fetchError', { message: `HTTP error! status: ${response.status}` });
            throw new FetchError(`HTTP error! status: ${response.status}`, response.status);
        }
    } catch (error) {
        if (shouldRefreshToken && ((error as FetchError).status === 401 || (error as FetchError).status === 403)) {
            try {
                const refreshTokenReponse: RefreshTokenResponse = await refreshAccessToken(refreshTokenUrl!, accessToken!, refreshToken);

                headers = {
                    ...headers,
                    Authorization: `${BEARER_PREFIX}${refreshTokenReponse?.refreshToken}`
                };

                fetchOptions = {
                    ...fetchOptions,
                    headers
                };

                setCookie(COOKIE_ACCESS_TOKEN, refreshTokenReponse?.accessToken);
                setCookie(COOKIE_REFRESH_TOKEN, refreshTokenReponse?.refreshToken);

                const retryResponse = await fetch(url, fetchOptions);

                if (retryResponse.ok) {
                    return await retryResponse.json();
                } else {
                    emitEvent('sessionExpired', { message: 'Session has expired after retry' });
                    throw new FetchError(`HTTP error! status: ${retryResponse.status}`, retryResponse.status);
                }
            } catch (retryError) {
                emitEvent(CUSTOM_EVENT_SESSION_REFRESH_ERROR, { message: 'Failed to refresh token or fetch data after retry' });
                throw new FetchError('Failed to refresh token or fetch data after retry');
            }
        } else {
            handleFetchError(error);
            if (error instanceof Error) {
                emitEvent('fetchError', { message: error.message });
            } else {
                emitEvent('fetchError', { message: 'An unknown error occurred during fetch' });
            }
            throw error;
        }
    }
};
