import React from 'react';

export const IntuitDomeIcon = () => (
    <svg width='69' height='41' viewBox='0 0 69 41' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g clipPath='url(#clip0_1472_3042)'>
            <path
                d='M11.9892 38.1989C13.3426 36.7964 14.0879 34.94 14.0879 32.9729C14.0879 31.0057 13.3426 29.15 11.9892 27.7468C10.6359 26.3444 8.82788 25.5541 6.89855 25.5215H0.716553V40.4249H6.89922C8.82722 40.3923 10.6359 39.6021 11.9892 38.1996V38.1989ZM2.94855 27.7944H6.89922C8.25588 27.7944 9.53188 28.3333 10.4912 29.3111C11.4506 30.2889 11.9792 31.5894 11.9792 32.9722C11.9792 34.355 11.4506 35.6555 10.4912 36.6333C9.53188 37.6111 8.25588 38.15 6.89922 38.15H2.94855V27.7944Z'
                fill='#121212'
            />
            <path
                d='M48.4485 28.0904V40.3981H50.7645V25.4702H46.9492L42.8738 36.2546L38.8445 25.4702H34.9872V40.3981H37.3025V28.0904H37.5145L42.0232 40.3981H43.7278L48.2365 28.0904H48.4485Z'
                fill='#121212'
            />
            <path
                d='M21.0992 28.7369C22.0352 27.9724 23.2119 27.5518 24.4112 27.5518C25.6105 27.5518 26.7872 27.9731 27.7232 28.7369C28.6385 29.4843 29.2899 30.5314 29.5632 31.6927H32.0645C31.7732 29.8614 30.8585 28.1905 29.4799 26.9756C28.0725 25.7348 26.2725 25.0499 24.4119 25.0479C22.5512 25.0499 20.7505 25.7341 19.3425 26.9749C17.9639 28.1899 17.0485 29.8614 16.7572 31.6927H19.2585C19.5319 30.5314 20.1832 29.4843 21.0985 28.7369H21.0992Z'
                fill='#121212'
            />
            <path
                d='M19.341 38.8818C20.749 40.1226 22.5497 40.8055 24.411 40.8055C26.2724 40.8055 28.0724 40.1226 29.481 38.8818C30.8597 37.6669 31.7744 35.9953 32.065 34.1641H29.5637C29.2897 35.3253 28.6384 36.3724 27.7237 37.1199C26.7877 37.8843 25.6117 38.3063 24.4124 38.3076C23.2124 38.3063 22.0364 37.8843 21.1004 37.1199C20.185 36.3724 19.5337 35.3253 19.259 34.1641H16.7577C17.0477 35.9953 17.9624 37.6669 19.3417 38.8818H19.341Z'
                fill='#121212'
            />
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M68.0023 37.7749L68.159 37.5805L66.143 36.0408L66.0057 36.2569C65.223 37.4888 63.5843 38.6467 61.4523 38.6467C58.623 38.6467 56.4637 36.5341 56.2823 33.882H68.543L68.5617 33.6625C68.5943 33.282 68.5943 32.9334 68.5943 32.6569V32.6541C68.5943 30.6292 67.969 28.7769 66.729 27.4288C65.4864 26.0779 63.6463 25.2544 61.2624 25.2544C56.921 25.2544 53.5497 28.6002 53.5497 33.0863C53.5497 37.5724 56.9243 40.9182 61.4523 40.9182C63.9563 40.9182 66.2117 39.9955 68.0023 37.7755V37.7749ZM65.873 31.7314H56.283C56.587 29.4884 58.5603 27.4913 61.215 27.4913C62.699 27.4913 63.8317 27.9744 64.6083 28.7633C65.3364 29.5033 65.7697 30.53 65.873 31.7314Z'
                fill='#121212'
            />
            <path
                d='M35.5219 14.3704C35.5219 17.8562 38.2819 20.403 41.7866 20.403C45.2912 20.403 48.0532 17.8596 48.0532 14.3704V6.37061H44.6579V13.9599C44.6579 15.8401 43.4152 17.1013 41.7772 17.1013C40.1392 17.1013 38.8966 15.8401 38.8966 13.9599V6.37061H35.5052L35.5226 14.371H35.5219V14.3704Z'
                fill='#121212'
            />
            <path d='M57.1244 9.58124H61.1751V20.125H64.5684V9.58124H68.6164V6.37061H57.1244V9.58124Z' fill='#121212' />
            <path d='M54.9318 6.37061H51.5385V20.125H54.9318V6.37061Z' fill='#121212' />
            <path d='M21.8118 9.58124H25.8618V20.125H29.2552V9.58124H33.3032V6.37061H21.8112V9.58124H21.8118Z' fill='#121212' />
            <path d='M4.00979 6.37061H0.616455V20.125H4.00979V6.37061Z' fill='#121212' />
            <path
                d='M20.026 12.1244C20.026 8.63924 17.2646 6.0918 13.76 6.0918C10.2553 6.0918 7.49329 8.63516 7.49329 12.1244V20.1248H10.8866V12.5355C10.8866 10.6553 12.1313 9.39417 13.7673 9.39417C15.4033 9.39417 16.648 10.6553 16.648 12.5355V20.1255H20.0413L20.026 12.1251V12.1244Z'
                fill='#121212'
            />
        </g>
        <defs>
            <clipPath id='clip0_1472_3042'>
                <rect width='68' height='41' fill='white' transform='translate(0.616455)' />
            </clipPath>
        </defs>
    </svg>
);
