import { isBrowser } from '../utils';

export const emitEvent = (eventName: string, detail: { message: string }) => {
    if (!isBrowser()) {
        return;
    }
    const event = new CustomEvent(eventName, { detail });
    window.dispatchEvent(event);
};

export const onEvent = (eventName: string, callback: (this: Window, ev: CustomEvent) => any) => {
    if (!isBrowser()) {
        return;
    }
    window.addEventListener(eventName, callback as EventListener);
};

export const offEvent = (eventName: string, callback: (this: Window, ev: CustomEvent) => any) => {
    if (!isBrowser()) {
        return;
    }
    window.removeEventListener(eventName, callback as EventListener);
};
