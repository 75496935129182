import React from 'react';

export const OrderIcon = () => (
    <svg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M6 2.36328L3 0.363281L0 2.36328V17.3633C0 19.0202 1.34315 20.3633 3 20.3633H17C18.6569 20.3633 20 19.0202 20 17.3633V14.3633H18V2.36328L15 0.363281L12 2.36328L9 0.363281L6 2.36328ZM16 14.3633H4V17.3633C4 17.9156 3.55228 18.3633 3 18.3633C2.44772 18.3633 2 17.9156 2 17.3633V3.43365L3 2.76698L6 4.76698L9 2.76698L12 4.76698L15 2.76698L16 3.43365V14.3633ZM17 18.3633H5.82929C5.93985 18.0505 6 17.7139 6 17.3633V16.3633H18V17.3633C18 17.9156 17.5523 18.3633 17 18.3633Z'
            fill='black'
        />
    </svg>
);
