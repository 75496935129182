import { isBrowser } from '.';

export const getCookie = (name: string): string | undefined => {
    if (!isBrowser()) {
        return undefined;
    }

    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts
            .pop()
            ?.split(';')
            .shift();
    }
    return undefined;
};
