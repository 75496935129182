import { API_URL, customFetch } from '../..';
import { getCookie } from '../../../utils';
import { Order } from './types';

export const getOrder = async (): Promise<Order | undefined> => {
    const identityId = getCookie('identityId');
    const eventId = getCookie('eventId');
    const locationId = getCookie('locationId');

    const url = `${API_URL}/suites/orders/${identityId}?eventId=${eventId}&locationId=${locationId}&showCartDetails=true`;

    try {
        const response = await customFetch(url);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        return undefined;
    }
};

export const getOrderById = async (): Promise<Order | undefined> => {
    const orderId = getCookie('orderId');
    const url = `${API_URL}/suites/orders/${orderId}/id?showCartDetails=true`;
    try {
        const response = await customFetch(url);
        const data = await response;
        return data;
    } catch (error) {
        console.error('Failed to fetch data:', error);
        throw new Error('Failed to fetch data');
    }
};

export * from './mocks';
export * from './types';
