/**
 * Transforms a URL by decoding it and then replacing spaces with '%20'.
 *
 * @param {string} inputUrl - The URL to be transformed.
 * @returns {string} - The transformed URL.
 *
 * @example
 * // The function should be called after the HTML has loaded to ensure it operates on the correct URL.
 * const inputUrl = 'https://images-us-prod.cms.commerce.dynamics.com/cms/api/skhrmstldd/imageFileData/search%3FfileName%3D/Categories/BEER.png';
 * const transformedUrl = transformUrl(inputUrl);
 * console.log(transformedUrl);
 * // Output: https://images-us-prod.cms.commerce.dynamics.com/cms/api/skhrmstldd/imageFileData/search?fileName=/Categories/BEER.png
 */
export const transformUrl = (inputUrl: string): string => {
    const decodedUrl = decodeURIComponent(inputUrl);
    const transformedUrl = decodedUrl.replace(/ /g, '%20');

    return transformedUrl;
};
