import { isBrowser, getCookie } from '.';

export const checkRequiredCookies = (cookieNames: string[]): void => {
    if (!isBrowser()) {
        return undefined;
    }

    const missingCookies = cookieNames.filter(cookieName => !getCookie(cookieName));

    if (missingCookies.length > 0) {
        throw new Error(`Missing required cookies: ${missingCookies.join(', ')}`);
    }
};
