import { useEffect } from 'react';

export const useHashChangeObserver = () => {
    const handleHashChange = () => {
        window.location.reload();
    };

    useEffect(() => {
        window.addEventListener('hashchange', handleHashChange);
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);
};
