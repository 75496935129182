import React from 'react';

export const EditIcon = () => (
    <svg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M8.48527 5.38419L7.54247 4.44138L1.33333 10.6505V11.5933H2.27614L8.48527 5.38419ZM9.42807 4.44138L10.3709 3.49857L9.42807 2.55577L8.48527 3.49857L9.42807 4.44138ZM2.82843 12.9267H0V10.0983L8.95667 1.14155C9.21707 0.881201 9.63913 0.881201 9.89947 1.14155L11.7851 3.02717C12.0455 3.28752 12.0455 3.70963 11.7851 3.96998L2.82843 12.9267Z'
            fill='#014B91'
        />
    </svg>
);
