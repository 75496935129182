import React from 'react';

export const DividerIcon = () => (
    <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='5' viewBox='0 0 345 5' fill='none'>
        <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M1.17247 4.11656C6.53736 4.8423 152.588 4.99292 194.623 4.32196C194.686 4.32196 274.843 3.2265 308.484 3.2265C326.03 3.2265 356.263 2.71986 340.673 0.967132C338.149 0.679576 282.985 0.679576 266.26 0.967132C226.56 1.6381 151.262 2.36383 88.2727 2.54184C51.6021 2.65139 27.05 2.85692 6.53736 3.19925C0.856895 3.2951 -1.66774 3.73315 1.17247 4.11656Z'
            fill='#E2E2E2'
        />
    </svg>
);
