import React, { useState } from 'react';
import { useOrderManagement } from '../../context';
import { MenuType } from '../../services';
import { getCookie, transformUrl } from '../../utils';
import { COOKIE_ORDER_ID, URL_IMAGES, COOKIE_MENU_TYPE } from '../../components/constants';
import ServiceFeeDisclaimer from '../../components/service-fee-disclaimer';
import Modal from 'react-modal';
import { defaultFontFamily, disclaimerStyle } from '../../styles';
import { InfoIcon } from '../cart-detail/info-icon';
import { CloseIcon } from './close-icon';

type ProductDetailPageProps = {
    navigateToCategoryProductsPage: (categoryId?: number) => void;
    navigateToCart: () => void;
};

export const ProductDetailPage = ({ navigateToCart }: ProductDetailPageProps) => {
    const [productQuantity, setQuantity] = useState<number>(1);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [comment, setComment] = useState<string>('');
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const { createOrderAndAddProductToOrder, isOrderLoading, addProduct: addProductFromContext, product } = useOrderManagement();

    const MAX_COMMENT_LENGTH = 500;
    const commentLength = comment.length;
    const isOverLimit = commentLength > MAX_COMMENT_LENGTH;
    const menuType: MenuType = getCookie(COOKIE_MENU_TYPE) as MenuType;

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: '10%',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '10px 20px'
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.6)'
        }
    };

    Modal.setAppElement('#main');

    const handleBack = () => {
        history.back();
    };

    const handleQuantityChange = (action: 'add' | 'subtract') => {
        setQuantity(prevQuantity => {
            if (action === 'add') {
                return prevQuantity + 1;
            } else if (action === 'subtract') {
                return prevQuantity > 1 ? prevQuantity - 1 : 1;
            }
            return prevQuantity;
        });
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(event.target.value);
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    if (!product) {
        return <></>;
    }

    const addProduct = async () => {
        setIsLoading(true);
        try {
            if (getCookie(COOKIE_ORDER_ID)) {
                await addProductFromContext(product, productQuantity, comment);
            } else {
                await createOrderAndAddProductToOrder(product, productQuantity, comment);
            }

            navigateToCart();
        } catch (error) {
            console.error('Failed to add product to order:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <section style={{ marginBottom: '24px' }}>
                <div key={product?.productId}>
                    <div style={{ marginBottom: '24px' }}>
                        <button
                            onClick={handleBack}
                            style={{
                                ...defaultFontFamily,
                                display: 'flex',
                                border: 'none',
                                background: 'transparent',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                fontSize: '16px',
                                marginBottom: '12px'
                            }}
                        >
                            &lt; Back
                        </button>
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <img
                            src={transformUrl(`${URL_IMAGES}${product.primaryImageUrl}`)}
                            alt={product.name}
                            style={{ width: '100%', height: 'auto', marginBottom: '32px' }}
                        />
                        <div style={{ ...defaultFontFamily, alignSelf: 'flex-start', width: '100%' }}>
                            <span style={{ fontSize: '24px', fontWeight: 700, display: 'block', marginBottom: '18px' }}>
                                {!!product.name ? product.name : '-'}
                            </span>
                            <span style={{ fontSize: '20px', fontWeight: 600, display: 'block', marginBottom: '18px' }}>
                                ${product.price.toFixed(2)}
                            </span>
                            <span style={{ display: 'block', marginBottom: '32px' }}>{product?.description ?? '-'}</span>
                            <div style={{ marginBottom: '16px' }}>
                                <label
                                    htmlFor='productQuantity'
                                    style={{ ...defaultFontFamily, fontWeight: 600, marginBottom: '12px', display: 'block' }}
                                >
                                    Quantity
                                </label>
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: '', gap: '12px' }}>
                                    <button
                                        style={{
                                            background: isOrderLoading || productQuantity === 1 ? '#bec0c2' : 'transparent',
                                            border: '1px solid gray',
                                            padding: '12px',
                                            height: '38px',
                                            width: '38px'
                                        }}
                                        onClick={() => handleQuantityChange('subtract')}
                                    >
                                        -
                                    </button>
                                    <input
                                        id='productQuantity'
                                        type='number'
                                        value={productQuantity}
                                        style={{
                                            border: '1px solid gray',
                                            textAlign: 'center',
                                            width: '100px',
                                            height: '34px'
                                        }}
                                    />
                                    <button
                                        style={{
                                            background: 'transparent',
                                            border: '1px solid gray',
                                            padding: '12px',
                                            height: '38px',
                                            width: '38px'
                                        }}
                                        onClick={() => handleQuantityChange('add')}
                                    >
                                        +
                                    </button>
                                </div>
                            </div>
                            {menuType === 'PRE' ? (
                                <div style={{ marginTop: '32px' }}>
                                    <label
                                        htmlFor='comment'
                                        style={{
                                            ...defaultFontFamily,
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            marginBottom: '8px',
                                            display: 'block',
                                            textTransform: 'uppercase'
                                        }}
                                    >
                                        Any allergies or special needs?
                                    </label>
                                    <textarea
                                        id='comment'
                                        value={comment}
                                        onChange={handleCommentChange}
                                        style={{
                                            ...defaultFontFamily,
                                            fontSize: '14px',
                                            border: 'none',
                                            borderBottom: '1px solid gray',
                                            width: '100%',
                                            padding: '8px',
                                            height: '80px',
                                            resize: 'none'
                                        }}
                                    />
                                    <span style={{ color: isOverLimit ? 'red' : 'gray', fontWeight: 400, fontSize: '14px' }}>
                                        {commentLength}/{MAX_COMMENT_LENGTH}
                                    </span>
                                </div>
                            ) : null}
                            <button
                                onClick={menuType === 'PRE' ? addProduct : handleOpen}
                                style={{
                                    fontSize: '16px',
                                    fontWeight: 600,
                                    background: isLoading || isOrderLoading || isOverLimit ? 'gray' : 'black',
                                    color: 'white',
                                    cursor: isLoading || isOrderLoading || isOverLimit ? 'not-allowed' : 'pointer',
                                    padding: '10px 24px',
                                    textTransform: 'uppercase',
                                    borderRadius: '20px 0 20px 0',
                                    marginTop: '16px',
                                    opacity: isLoading || isOrderLoading ? 0.7 : 1,
                                    textDecoration: 'none',
                                    width: '100%',
                                    textAlign: 'center'
                                }}
                                disabled={isLoading || isOrderLoading || isOverLimit}
                            >
                                {isLoading || isOrderLoading ? 'Adding...' : 'Add to order'}
                            </button>
                            <Modal isOpen={isOpen} onRequestClose={handleClose} style={customStyles} contentLabel='Confirm Order'>
                                <div key={product?.productId} style={{ fontSize: '10px', fontWeight: 300 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '24px' }}>
                                        <p
                                            style={{
                                                ...defaultFontFamily,
                                                margin: '0',
                                                fontSize: '16px',
                                                lineHeight: '24px',
                                                alignContent: 'center'
                                            }}
                                        >
                                            <b style={{ fontSize: '16px', color: '#000000' }}>
                                                {productQuantity} {productQuantity && productQuantity > 1 ? 'items' : 'item'}
                                            </b>{' '}
                                            <span style={{ ...defaultFontFamily, color: '#555' }}>will be added to order</span>
                                        </p>
                                        <button
                                            onClick={handleClose}
                                            style={{
                                                border: 'none',
                                                background: 'transparent',
                                                cursor: 'pointer',
                                                marginTop: '6px',
                                                padding: 0
                                            }}
                                            aria-label='Close'
                                        >
                                            <CloseIcon />
                                        </button>
                                    </div>
                                    <div
                                        style={{
                                            ...defaultFontFamily,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            marginBottom: '24px'
                                        }}
                                    >
                                        <img
                                            src={transformUrl(`${URL_IMAGES}${product?.primaryImageUrl}`)}
                                            alt={product?.name}
                                            style={{ width: '100%', height: 'auto' }}
                                        />
                                    </div>
                                    <p
                                        style={{
                                            ...defaultFontFamily,
                                            fontSize: '20px',
                                            fontWeight: 'bold',
                                            margin: '0px',
                                            marginBottom: '4px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        {!!product?.name ? product.name : '-'}
                                    </p>
                                    <p
                                        style={{
                                            ...defaultFontFamily,
                                            fontSize: '14px',
                                            fontWeight: 'bold',
                                            margin: '0px',
                                            marginBottom: '24px',
                                            textAlign: 'center'
                                        }}
                                    >
                                        ${product && productQuantity ? (Number(product?.price) * productQuantity).toFixed(2) : ''}
                                    </p>
                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', margin: '12px 0 4px 0' }}>
                                        <div>
                                            <InfoIcon />
                                        </div>
                                        <p style={disclaimerStyle}>
                                            With this action, you confirm your purchase. It cannot be canceled or modified.
                                        </p>
                                    </div>
                                    <button
                                        onClick={addProduct}
                                        style={{
                                            ...defaultFontFamily,
                                            background: 'black',
                                            color: 'white',
                                            textTransform: 'uppercase',
                                            borderRadius: '20px 0 20px 0',
                                            padding: '8px 24px',
                                            marginTop: '16px',
                                            cursor: 'pointer',
                                            textDecoration: 'none',
                                            width: '100%',
                                            textAlign: 'center',
                                            fontSize: '16px',
                                            fontWeight: 600
                                        }}
                                    >
                                        CONFIRM ORDER
                                    </button>
                                    <button
                                        onClick={handleClose}
                                        style={{
                                            ...defaultFontFamily,
                                            border: 'none',
                                            background: 'transparent',
                                            textDecoration: 'underline',
                                            cursor: 'pointer',
                                            marginBottom: '12px',
                                            marginTop: '12px',
                                            padding: 0,
                                            width: '100%',
                                            fontSize: '16px',
                                            fontWeight: 600
                                        }}
                                    >
                                        BACK
                                    </button>
                                </div>
                            </Modal>
                        </div>
                    </div>
                </div>
            </section>
            <ServiceFeeDisclaimer />
        </>
    );
};
