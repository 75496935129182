import { API_URL, Order, customFetch } from '../..';
import { COOKIE_ORDER_ID } from '../../../components/constants';
import { getCookie } from '../../../utils';

/**
 *
 * @param lineIds array of lineId to be deleted
 */
export const deleteProductsToOrder = async (lineIds: []): Promise<Order> => {
    const orderId = getCookie(COOKIE_ORDER_ID);
    const url = `${API_URL}/suites/orders/${orderId}/products/delete`;

    try {
        const response = await customFetch(url, {
            method: 'DELETE',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(lineIds)
        });

        return response;
    } catch (error) {
        throw new Error(`HTTP error: ${error}`);
    }
};
