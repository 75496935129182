import { Category } from '../../services/category/get-category/types';

/**
 * Generates a category tree based on the provided categories and parent ID.
 * @param {Category[]} categories - The list of categories or null if there are no categories.
 * @param {number} parent - The parent category ID. If not provided, the function will generate the top-level categories.
 * @returns {Category[]} The category tree with nested categories.
 * @example
 * // Example usage:
 * const categories = [
 *   { categoryId: 1, parentCategory: null, name: 'Electronics' },
 *   { categoryId: 2, parentCategory: 1, name: 'Smartphones' },
 *   { categoryId: 3, parentCategory: 1, name: 'Laptops' },
 *   { categoryId: 4, parentCategory: 2, name: 'Android' },
 *   { categoryId: 5, parentCategory: 2, name: 'iOS' },
 *   { categoryId: 6, parentCategory: 3, name: 'Windows' },
 * ];
 * const categoryTree = generateCategoryTree(categories);
 */
export function generateCategoryTree(categories: Category[], parent: number): Category[] {
    if (!categories) {
        return [];
    }

    const tree: Category[] = [];
    for (const currentCategory of categories) {
        if (currentCategory.parentCategory === parent) {
            const nestedCategories = generateCategoryTree(categories, currentCategory.categoryId);
            if (nestedCategories.length > 0) {
                currentCategory.nestedCategories = nestedCategories;
            }
            tree.push(currentCategory);
        }
    }
    return tree;
}
