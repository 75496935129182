import React from 'react';
import { CategoryProvider, NavigationProvider, OrderManagementProvider } from './context';
import { RouteSwitcher } from './pages';

const App = () => (
    <>
        <OrderManagementProvider>
            <CategoryProvider>
                <NavigationProvider>
                    <RouteSwitcher />
                </NavigationProvider>
            </CategoryProvider>
        </OrderManagementProvider>
    </>
);

export default App;
