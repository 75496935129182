import React, { useState, useEffect } from 'react';
import { CartLine, suspendOrder, MenuType } from '../../services';
import { COOKIE_CLOSE_WEB_VIEW_URL, URL_IMAGES, COOKIE_MENU_TYPE } from '../../components/constants';
import { getCookie, numberToCurrentcy, transformUrl } from '../../utils';
import { useCategory, useNavigation, useOrderManagement } from '../../context';
import OrderSummary from './order-summary';
import ServiceFeeDisclaimer from '../../components/service-fee-disclaimer';
import { defaultFontFamily, disclaimerStyle } from '../../styles';
import { EditIcon } from './edit-icon';
import { InfoIcon } from './info-icon';

export const CartDetail = () => {
    const [isSuspendingOrder, setIsSuspendingOrder] = useState(false);
    const { order, isOrderLoading, addComments } = useOrderManagement();
    const [comment, setComment] = useState<string>('');
    const [initComment, setInitComment] = useState<string>('');
    const [isSameComment, setSameComment] = useState(false);
    const [isEditComment, setIsEditComment] = useState(false);
    const { navigateToCategoriesPage, navigateToCartUpdate } = useNavigation();
    const { handleCategorySelection } = useCategory();
    const menuType: MenuType = getCookie(COOKIE_MENU_TYPE) as MenuType;

    const MAX_COMMENT_LENGTH = 500;
    const commentLength = comment.length;
    const isOverLimit = commentLength > MAX_COMMENT_LENGTH;

    const handleSuspendOrder = async () => {
        setIsSuspendingOrder(true);
        const result = await suspendOrder();
        setIsSuspendingOrder(false);
        const closeWebViewUrl = getCookie(COOKIE_CLOSE_WEB_VIEW_URL);
        if (result && closeWebViewUrl) {
            window.location.href = closeWebViewUrl;
        }
    };

    const handleAddMoreProducts = () => {
        const mainCategoryFromCookie = typeof window !== 'undefined' ? Number(getCookie('categoryId')) || 0 : 0;
        handleCategorySelection(mainCategoryFromCookie);
        navigateToCategoriesPage();
    };

    const handleCartUpdate = () => {
        navigateToCartUpdate();
    };

    const handleCommentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        const newComment = event.target.value;
        setComment(newComment);
        setSameComment(newComment === initComment);
    };

    useEffect(() => {
        if (order?.cart.attributeValues) {
            const specialRequest = order?.cart.attributeValues.find(attribute => attribute.Name === 'Special_Request');
            if (specialRequest) {
                setComment(specialRequest.TextValue || '');
                setInitComment(specialRequest.TextValue || '');
                setIsEditComment(specialRequest.TextValue !== '');
                setSameComment(true);
            }
        }
    }, [order]);

    return (
        <>
            <div
                style={{
                    borderBottom: '1px solid #EEEEEE',
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <h1 style={{ fontSize: '24px' }}>Your order</h1>
                    {menuType === 'PRE' ? (
                        <div
                            style={{
                                marginTop: '8px'
                            }}
                        >
                            <EditIcon />
                            <button
                                style={{
                                    ...defaultFontFamily,
                                    border: 'none',
                                    background: 'transparent',
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    marginTop: '10px',
                                    fontSize: '16px',
                                    fontWeight: 'bolder',
                                    color: '#014B91'
                                }}
                                onClick={handleCartUpdate}
                            >
                                Edit
                            </button>
                        </div>
                    ) : null}
                </div>
                {menuType === 'DOE' ? (
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '8px', margin: '12px 0 4px 0' }}>
                        <div>
                            <InfoIcon />
                        </div>
                        <p style={disclaimerStyle}>
                            You cannot remove or edit any item now, but you can
                            <button
                                style={{
                                    ...defaultFontFamily,
                                    border: 'none',
                                    background: 'transparent',
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={handleAddMoreProducts}
                            >
                                add more products here
                            </button>
                        </p>
                    </div>
                ) : null}
                <ul style={{ listStyleType: 'none', padding: 0 }}>
                    {order?.cart.cartLines.map((item: CartLine) => (
                        <li
                            key={item.lineId}
                            style={{
                                ...defaultFontFamily,
                                marginBottom: '10px',
                                padding: '10px'
                            }}
                        >
                            <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                                <div style={{ flexBasis: '40%' }}>
                                    <img
                                        src={transformUrl(`${URL_IMAGES}${item?.product?.primaryImageUrl ?? ''}`)}
                                        alt={item?.product?.name ?? ''}
                                        style={{ width: 'auto', height: 'auto', maxWidth: '110px', maxHeight: '86px' }}
                                    />
                                </div>
                                <div style={{ flexBasis: '60%', display: 'grid' }}>
                                    <span style={{ ...defaultFontFamily, fontWeight: 'bold', marginBottom: '8px' }}>
                                        {!!item.description ? item.product?.name : '-'}
                                    </span>
                                    <span style={{ ...defaultFontFamily, fontWeight: 600, marginBottom: '8px' }}>
                                        Quantity: {item.quantity}
                                    </span>
                                    <span style={{ ...defaultFontFamily, fontWeight: 600, marginBottom: '8px' }}>
                                        ${numberToCurrentcy(item.price * item.quantity)}
                                    </span>
                                </div>
                            </div>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <div style={{ margin: '32px 0' }}>
                    <label
                        htmlFor='comment'
                        style={{
                            ...defaultFontFamily,
                            fontWeight: 600,
                            fontSize: '12px',
                            marginBottom: '8px',
                            display: 'block',
                            textTransform: 'uppercase'
                        }}
                    >
                        Any allergies or special needs?
                    </label>
                    <textarea
                        id='comment'
                        value={comment}
                        onChange={handleCommentChange}
                        style={{
                            ...defaultFontFamily,
                            fontSize: '14px',
                            border: 'none',
                            borderBottom: '1px solid gray',
                            width: '100%',
                            padding: '8px',
                            height: '80px',
                            resize: 'none'
                        }}
                    />
                    <span style={{ color: isOverLimit ? 'red' : 'gray', fontWeight: 400, fontSize: '14px', display: 'flex' }}>
                        {commentLength}/{MAX_COMMENT_LENGTH}
                    </span>
                    <button
                        onClick={() => addComments(comment)}
                        style={{
                            fontSize: '16px',
                            fontWeight: 600,
                            background: isOrderLoading || isOverLimit ? 'gray' : 'white',
                            color: 'black',
                            cursor: isOrderLoading || isOverLimit ? 'not-allowed' : 'pointer',
                            padding: '10px 24px',
                            textTransform: 'uppercase',
                            borderRadius: '20px 0 20px 0',
                            marginTop: '16px',
                            opacity: isOrderLoading ? 0.7 : 1,
                            textDecoration: 'none',
                            width: '50%',
                            textAlign: 'center'
                        }}
                        disabled={isOrderLoading || isOverLimit || (!isEditComment && comment === '') || (isEditComment && isSameComment)}
                    >
                        {isEditComment ? 'Edit comment' : 'Add comment'}
                    </button>
                </div>
                <OrderSummary order={order!} />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '16px' }}>
                    <button
                        onClick={handleSuspendOrder}
                        style={{
                            ...defaultFontFamily,
                            background: 'black',
                            borderRadius: '20px 0 20px 0',
                            color: 'white',
                            padding: '12px',
                            textDecoration: 'none',
                            textTransform: 'uppercase',
                            width: '100%',
                            textAlign: 'center',
                            maxWidth: '348px',
                            cursor: 'pointer',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                        disabled={isSuspendingOrder}
                    >
                        {order?.paymentMethodId ? 'FINISH' : 'SELECT YOUR PAYMENT METHOD'}
                    </button>
                    <button
                        style={{
                            ...defaultFontFamily,
                            border: 'none',
                            background: 'transparent',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            marginTop: '10px',
                            marginBottom: '24px',
                            fontSize: '16px',
                            fontWeight: 600
                        }}
                        onClick={handleAddMoreProducts}
                    >
                        ADD MORE PRODUCTS
                    </button>
                </div>
            </div>
            <ServiceFeeDisclaimer />
        </>
    );
};
