import { getCookie, checkUrlImages } from '../utils';

export const PAGE_PRODUCT_DETAIL = '#productDetail';
export const PAGE_ITEM_LIST = '#itemList';
export const PAGE_CART_DETAIL = '#cartDetail';
export const PAGE_CART_UPDATE = '#cartUpdate';
export const PAGE_CATEGORY_LIST = '#categoryList';
export const PAGE_CATEGORY = '#main';

export const COOKIE_CATEGORY_ID = 'categoryId';
export const COOKIE_CLOSE_WEB_VIEW_URL = 'closeWebViewUrl';
export const COOKIE_COMMERCE_API_URL = `commerceApiUrl`;
export const COOKIE_ORDER_ID = 'orderId';
export const COOKIE_WEBVIEW_URL = 'webViewUrl';
export const COOKIE_IMAGES_URL = 'webViewImagesUrl';
export const COOKIE_X_API_KEY = 'xApiKey';
export const COOKIE_ACCESS_TOKEN = 'token';
export const COOKIE_REFRESH_TOKEN = 'refreshToken';
export const COOKIE_SUITE_ID = 'locationId'; // LOCATION ID in our API, microsoft calls it SUITE_ID
export const COOKIE_IDENTITY_ID = 'identityId';
export const COOKIE_EVENT_ID = 'eventId';
export const COOKIE_LOCATION_ID = 'locationId';
export const COOKIE_PACKAGE_ID = 'packageId';
export const COOKIE_REFRESH_TOKEN_URL = 'refreshTokenUrl';
export const COOKIE_CLOSE_WEBVIEW_TO_PREVIOUS_URL = 'closeWebViewToPreviousUrl';
export const COOKIE_MENU_TYPE = 'menuType';
export const COOKIE_SECTION = 'webViewSection';

export const requiredCookies = [
    COOKIE_CATEGORY_ID,
    COOKIE_CLOSE_WEB_VIEW_URL,
    COOKIE_COMMERCE_API_URL,
    COOKIE_IMAGES_URL,
    COOKIE_WEBVIEW_URL,
    COOKIE_EVENT_ID,
    COOKIE_IDENTITY_ID,
    COOKIE_SUITE_ID,
    COOKIE_LOCATION_ID,
    COOKIE_MENU_TYPE,
    COOKIE_PACKAGE_ID

    // COOKIE_X_API_KEY,
    // COOKIE_ACCESS_TOKEN,
    // COOKIE_REFRESH_TOKEN,
    // COOKIE_REFRESH_TOKEN_URL,
    // COOKIE_CLOSE_WEBVIEW_TO_PREVIOUS_URL
];

export const URL_IMAGES = getCookie(COOKIE_IMAGES_URL) ? checkUrlImages(getCookie(COOKIE_IMAGES_URL)) : '';

export const CUSTOM_EVENT_EMPTY_CATEGORY = 'EMPTY_CATEGORY';
export const CUSTOM_EVENT_SESSION_REFRESH_ERROR = 'SESSION_REFRESH_ERROR';

export const ERROR_MESSAGE_COOKIES = `Required cookies are missing. Please check your browser settings.`;
export const ERROR_MESSAGE_CATEGORY_ERROR = `There was an error loading your category. Please try again.`;
export const ERROR_MESSAGE_ORDER_ERROR = `There was an error loading your order. Please try again.`;

export const DOE_DISABLED_MESSAGE = `Orders from the app are not available today. Please refer to our team assistants to order. Thank you for your understanding.`;

export const MENU_TYPE_PRE = 'PRE';
export const MENU_TYPE_DOE = 'DOE';
