import React, { useCallback, useEffect, useState } from 'react';
import { Category } from '../../services/category/get-category/types';
import { getCookie } from '../../utils';
import { useCategory } from '../../context';

interface BreadcrumbsProps {
    categories: Category[];
    categoryId: number;
    navigateToCategoriesPage: () => void;
}

const mainCategoryFromCookie = typeof window !== 'undefined' ? Number(getCookie('categoryId')) || 0 : 0;

export const Breadcrumbs = ({ categories, categoryId, navigateToCategoriesPage }: BreadcrumbsProps) => {
    const [breadcrumbs, setBreadcrumbs] = useState<Category[]>([]);
    const { handleCategorySelection } = useCategory();

    /**
     * Finds the path to a category by its ID within a category tree.
     * @param {Category[]} categories - The list of categories.
     * @param {number} categoryId - The ID of the category to find.
     * @param {Category[]} path - The current path of categories.
     * @returns {Category[]} - The array of categories representing the path to the category.
     */
    const findCategoryPath = useCallback((categories: Category[], categoryId: number, path: Category[] = []): Category[] => {
        for (const category of categories) {
            if (category.categoryId === categoryId) {
                return [...path, category];
            }
            if (category.nestedCategories && category.nestedCategories.length > 0) {
                const result = findCategoryPath(category.nestedCategories, categoryId, [...path, category]);
                if (result.length > 0) {
                    return result;
                }
            }
        }
        return [];
    }, []);

    useEffect(() => {
        if (!Array.isArray(categories)) {
            return;
        }

        const newBreadcrumbs = findCategoryPath(categories, categoryId || 0);
        if (newBreadcrumbs.length === 0 && mainCategoryFromCookie !== categoryId) {
            const fallbackBreadcrumbs = findCategoryPath(categories, mainCategoryFromCookie);
            setBreadcrumbs(fallbackBreadcrumbs);
        } else {
            setBreadcrumbs(newBreadcrumbs);
        }
    }, [categories, categoryId, findCategoryPath]);

    const renderBreadcrumbs = () => (
        <nav aria-label='breadcrumb'>
            <ol
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '8px',
                    listStyleType: 'none',
                    paddingLeft: 0
                }}
            >
                <li key='back-to-top'>
                    <a
                        href='#'
                        onClick={e => {
                            e.preventDefault();
                            handleCategorySelection(mainCategoryFromCookie);
                            navigateToCategoriesPage();
                        }}
                        style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }}
                    >
                        Back to top
                    </a>
                    <span>{' / '}</span>
                </li>
                {breadcrumbs.map((category, index) => (
                    <li key={category.categoryId}>
                        {index < breadcrumbs.length - 1 ? (
                            <>
                                <a
                                    style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline' }}
                                    href='#'
                                    onClick={e => {
                                        e.preventDefault();
                                        handleCategorySelection(category.categoryId);
                                        navigateToCategoriesPage();
                                    }}
                                >
                                    {category.name}
                                </a>
                                <span>{' / '}</span>
                            </>
                        ) : (
                            <span>{category.name}</span>
                        )}
                    </li>
                ))}
            </ol>
        </nav>
    );

    return <div>{mainCategoryFromCookie === categoryId ? <></> : renderBreadcrumbs()}</div>;
};
