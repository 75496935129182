/**
 * Styles for the commerce web module
 * This is a place to use the actual styles
 * defined in Figma
 * @see https://www.figma.com/design/qic4CqqpN3btKBYF83zwOv/%5BMockups---Fan-App%5D-Suites-Management
 */

export const defaultFontFamily = {
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif',
    color: '#121212'
};

export const disclaimerStyle = {
    ...defaultFontFamily,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    margin: '0px'
};
