import { API_URL, Order, customFetch } from '../..';
import { COOKIE_ORDER_ID } from '../../../components/constants';
import { getCookie } from '../../../utils';

/**
 *
 * @param comments comments to be added to order
 */
export const addCommentsToOrder = async (comments: string): Promise<Order> => {
    const orderId = getCookie(COOKIE_ORDER_ID);
    const url = `${API_URL}/suites/orders/${orderId}/comments`;

    try {
        const response = await customFetch(url, {
            method: 'PATCH',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ comments: comments })
        });
        return response;
    } catch (error) {
        throw new Error(`HTTP error: ${error}`);
    }
};
