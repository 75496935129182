import React from 'react';
import { URL_IMAGES } from '../../components/constants';
import { Category } from '../../services/category/get-category/types';
import { transformUrl } from '../../utils';
import { useCategory, useNavigation } from '../../context';
import { defaultFontFamily } from '../../styles';
import { ArrowIcon } from './arrow-icon';

interface CategoriesPageProps {
    categories: Category[];
    categoryId?: number;
    navigateToCategoryPage: (category: Category) => void;
    navigateToCategoryProductsPage: (categoryId: number) => void;
    cookieCategory?: Category;
}

export const CategoriesPage = ({ cookieCategory }: CategoriesPageProps) => {
    const { filteredCategories } = useCategory();
    const { navigateToCategoryProductsPage } = useNavigation();

    const handleError = (e: any) => {
        e.target.src =
            'https://images-us-prod.cms.commerce.dynamics.com/cms/api/skhrmstldd/imageFileData/search?fileName=/Products/Z0209_000_001.png';
    };

    const handleErrorHeader = (e: any) => {
        e.target.src = '';
        e.target.style.display = 'none';
    };

    const generateLink = (c: Category) => {
        return (
            <div key={c.categoryId} id={`category-${c.categoryId}`} style={{ border: '1px solid #e2e2e2', borderWidth: '1px 0' }}>
                <button
                    style={{
                        ...defaultFontFamily,
                        color: 'black',
                        cursor: 'pointer',
                        border: 'none',
                        background: 'transparent',
                        textAlign: 'left',
                        padding: 0,
                        width: '100%',
                        display: 'flex'
                    }}
                    onClick={() => navigateToCategoryProductsPage(c.categoryId)}
                >
                    <img
                        style={{ aspectRatio: '1/1', width: '48px', margin: '16px 16px 16px 24px', borderRadius: '50%' }}
                        src={transformUrl(`${URL_IMAGES}${c.image}`)}
                        alt={c.name}
                        onError={e => handleError(e)}
                    />
                    <p style={{ fontSize: '14px', fontWeight: 600, lineHeight: '16px', margin: 'auto 0' }}>{c?.name}</p>
                    <div style={{ margin: 'auto 24px auto auto' }}>
                        <ArrowIcon />
                    </div>
                </button>
            </div>
        );
    };

    return (
        <div>
            {cookieCategory?.image ? (
                <img
                    style={{ height: '150px', display: 'flex', margin: 'auto' }}
                    src={transformUrl(`${URL_IMAGES}${cookieCategory.image}`)}
                    alt={cookieCategory.name}
                    onError={e => handleErrorHeader(e)}
                />
            ) : null}
            {cookieCategory?.name ? <h2 className='header-title'>{cookieCategory?.name}</h2> : null}
            {cookieCategory?.localizedDescription ? (
                <p style={{ ...defaultFontFamily, margin: '0px 24px 24px 24px', lineHeight: '1.2' }}>
                    <span style={{ display: 'block' }}>{cookieCategory?.localizedDescription}</span>
                </p>
            ) : null}
            <div style={{ marginBottom: '24px' }}>{filteredCategories.map((c: Category) => generateLink(c))}</div>
        </div>
    );
};
