import React from 'react';

export const CloseIcon = () => (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M12.3894 11.0037L17.3394 6.05371L18.7534 7.46771L13.8034 12.4177L18.7534 17.3677L17.3394 18.7817L12.3894 13.8317L7.43939 18.7817L6.02539 17.3677L10.9754 12.4177L6.02539 7.46771L7.43939 6.05371L12.3894 11.0037Z'
            fill='#121212'
        />
    </svg>
);
