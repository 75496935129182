import { FetchError } from '../..';
import { COOKIE_SUITE_ID } from '../../../components/constants';
import { isBrowser, getCookie } from '../../../utils';
import { API_URL } from '../../constants';
import { customFetch } from '../../custom-fetch';
import { Product } from './types';

/**
 * Fetch products associated with a category.
 *
 * @param categoryId - The ID of the category to fetch products for.
 * @returns A promise that resolves to the products data or throws an error.
 */
export const getProductsByCategoryId = async (categoryId: number): Promise<Product[] | undefined> => {
    const suiteId = isBrowser() && getCookie(COOKIE_SUITE_ID);
    const url = `${API_URL}/product-management/suites/${suiteId}/categories/${categoryId}/products`;
    try {
        const response = await customFetch(url);
        if (!Array.isArray(response) && response?.status === 400) {
            throw new FetchError(response?.message, (response as FetchError)?.status);
        }
        const data = await response;
        return data;
    } catch (error) {
        console.error('Failed to fetch products data:', error);
        if (error instanceof Error) {
            throw new FetchError(error.message, (error as FetchError).status);
        } else {
            throw new FetchError('Unknown error');
        }
    }
};

export * from './mocks';
export * from './types';
