import { API_URL, FetchError, customFetch } from '../..';
import { COOKIE_SUITE_ID } from '../../../components/constants';
import { getCookie } from '../../../utils';
import { Category } from '../get-category/types';

export const getCategories = async (): Promise<Category[] | null> => {
    const suiteId = getCookie(COOKIE_SUITE_ID);
    const url = `${API_URL}/product-management/suites/${suiteId}/categories/brief`;

    const response = await customFetch(url);

    if (Array.isArray(response)) {
        return response;
    }

    console.error('getCategories: Failed to fetch categories', response);
    throw new FetchError('getCategories: Failed to fetch categories', response);
};

export * from './mocks';
