import { API_URL, Order, customFetch, MenuType } from '../..';
import { COOKIE_MENU_TYPE, COOKIE_ORDER_ID } from '../../../components/constants';
import { getCookie } from '../../../utils';

/**
 *
 * @param productId This should be mapped to product.recordId
 * @param quantity Quantity of products added to the order.
 * @param ammount Mapped to product.price
 * @param comment Optional comment for the product.'
 * @param name: Product name.
 */
export const addProductToOrder = async (
    recordId: number,
    quantity: number,
    amount: number,
    comment: string,
    name: string
): Promise<Order | void> => {
    const orderId = getCookie(COOKIE_ORDER_ID);
    const menuType: MenuType = getCookie(COOKIE_MENU_TYPE) as MenuType;

    const url = `${API_URL?.replace('/v1', '/v2')}/suites/orders/${orderId}/products/add`;
    const defaultRequestBody = {
        amount: amount,
        menuType,
        productId: recordId,
        quantity,
        comment,
        name
    };
    let requestBody = { ...defaultRequestBody };

    if (!!comment) {
        requestBody = {
            ...defaultRequestBody,
            comment
        };
    }

    try {
        const response = await customFetch(url, {
            method: 'POST',
            headers: {
                accept: '*/*',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        });

        const resp = await response;
        return resp;
    } catch (error) {
        throw new Error(`HTTP error: ${error}`);
    }
};
