import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { COOKIE_ORDER_ID, CUSTOM_EVENT_SESSION_REFRESH_ERROR } from '../../components/constants';
import {
    Order,
    getOrderById,
    resumeOrder,
    addCommentsToOrder,
    addProductToOrder,
    deleteProductsToOrder,
    updateProductsToOrder,
    FetchError,
    ORDER_STATUS_NEW,
    ORDER_STATUS_OPEN,
    ORDER_STATUS_SUSPENDED,
    suspendOrder,
    Product
} from '../../services';
import { CreateOrderResponse, createOrder } from '../../services/order/create-order';
import { getCookie, setGenericOrder, isBrowser, setCookie } from '../../utils';
import { AppError, OrderManagementContextProps } from '../types';
import { offEvent, onEvent } from '../../events';
import { setOrderWithCartLines } from './set-order-with-cart-lines';

const OrderManagementContext = createContext<OrderManagementContextProps | undefined>(undefined);

export const useOrderManagement = () => {
    const context = useContext(OrderManagementContext);
    if (!context) {
        throw new Error('useOrderManagement must be used within an OrderManagementProvider');
    }
    return context;
};

export const OrderManagementProvider: React.FC = ({ children }) => {
    const [order, setOrder] = useState<Order | undefined>();
    const [orderError, setOrderError] = useState<AppError>({});
    const [isOrderLoading, setIsOrderLoading] = useState<boolean>(false);
    const [product, setProduct] = useState<Product>();
    const [productQuantity, setProductQuantity] = useState<number>(1);

    const orderId = getCookie(COOKIE_ORDER_ID);
    const shouldFetchOrder = useRef(false);

    const updateOrderStatus = (status: string) => {
        if (order) {
            setOrder({
                ...order,
                status
            });
        }
    };

    /**
     * Fetches the order from the server based on the order ID cookie.
     * Handles different order statuses and updates the state accordingly.
     */
    const fetchOrder = async () => {
        if (!isBrowser()) {
            return;
        }
        try {
            setIsOrderLoading(true);
            setOrderError(prev => ({ ...prev, orderFetchError: undefined }));
            const orderById: Order | undefined = await getOrderById();

            if (orderById) {
                const { status } = orderById;
                let updatedOrder = orderById;
                if (status === ORDER_STATUS_NEW) {
                    updatedOrder = setGenericOrder(orderById);
                } else if (status === ORDER_STATUS_OPEN) {
                    const suspendedOrder = await suspendOrder();
                    if (suspendedOrder?.status === ORDER_STATUS_SUSPENDED) {
                        const suspendedOrderById: Order | undefined = await getOrderById();
                        if (suspendedOrderById) {
                            updatedOrder = suspendedOrderById;
                        }
                    }
                }
                setOrderWithCartLines(updatedOrder, setGenericOrder, setOrder);
            }
        } catch (error) {
            console.error('Failed to fetch order:', error);
            let orderFetchError = 'Failed to fetch order. Please try again.';
            if (error instanceof FetchError) {
                if (error.status === 400) {
                    orderFetchError = 'Bad Request: The server could not understand the request to fetch the order.';
                } else if (error.status === 404) {
                    orderFetchError = 'Not Found: The requested order could not be found.';
                } else {
                    orderFetchError = error.message;
                }
            }
            setOrderError(prev => ({ ...prev, orderFetchError }));
        } finally {
            setIsOrderLoading(false);
        }
    };

    useEffect(() => {
        void onEvent(CUSTOM_EVENT_SESSION_REFRESH_ERROR, () => {
            setOrderError(prev => ({ ...prev, userAuthenticationError: 'Session expired.' }));
        });

        if (orderId) {
            shouldFetchOrder.current = true;
        }

        if (shouldFetchOrder.current) {
            setTimeout(() => {
                void fetchOrder();
            });
        }

        return () => {
            offEvent(CUSTOM_EVENT_SESSION_REFRESH_ERROR, () => {});
        };
    }, []);

    /**
     * Creates an order if it does not exist and adds a product to the order.
     * Handles the order creation, resuming suspended orders, and updating the order with the new product.
     *
     * @param {Product} product - The product to add to the order.
     * @param {number} quantity - The quantity of the product to add.
     * @param {string} comment - Optional comment for the product.
     */
    const createOrderAndAddProductToOrder = async (product: Product, quantity: number, comment: string) => {
        try {
            setIsOrderLoading(true);
            let currentOrder = order;
            const cookieId = getCookie(COOKIE_ORDER_ID);

            if (!cookieId) {
                const createdOrder: CreateOrderResponse | undefined = await createOrder();
                if (!createdOrder) {
                    setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error creating the order.' }));
                    throw new Error('Failed to create order');
                }
                setCookie(COOKIE_ORDER_ID, createdOrder?.orderId);
                currentOrder = await getOrderById();
                if (currentOrder) {
                    setOrderWithCartLines(currentOrder, setGenericOrder, setOrder);
                }
            }

            if (!currentOrder) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'Order not found.' }));
                throw new Error('Order not found');
            }

            if (currentOrder.status === ORDER_STATUS_SUSPENDED) {
                const resumedOrder = await resumeOrder();
                if (!resumedOrder || resumedOrder.status === ORDER_STATUS_SUSPENDED || resumedOrder.status === 400) {
                    setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error resuming the order.' }));
                    throw new Error('Failed to resume order');
                }
                currentOrder = resumedOrder;
                setOrderWithCartLines(currentOrder, setGenericOrder, setOrder);
            }

            const updatedServerCart = await addProductToOrder(product.recordId, quantity, product.price, comment, product.name);
            if (updatedServerCart === undefined || !updatedServerCart) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error adding product to order.' }));
                throw new Error('Failed to add product to order');
            }
            setOrder(updatedServerCart);

            setOrderError(prev => ({ ...prev, orderUpdateError: undefined }));
        } catch (error) {
            setOrderError(prev => ({ ...prev, orderUpdateError: (error as Error).message }));
        } finally {
            setIsOrderLoading(false);
        }
    };

    const addProduct = async (product: Product, quantity: number, comment: string) => {
        try {
            setIsOrderLoading(true);
            if (order?.status === ORDER_STATUS_SUSPENDED) {
                setOrderWithCartLines(order, setGenericOrder, setOrder);
                const resumedOrder = await resumeOrder();
                if (!resumedOrder || resumedOrder.status === ORDER_STATUS_SUSPENDED || resumedOrder.status === 400) {
                    setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error resuming the order.' }));
                    throw new Error('Failed to resume order');
                }

                if (resumedOrder && resumedOrder?.status === ORDER_STATUS_OPEN) {
                    updateOrderStatus(ORDER_STATUS_OPEN);
                }
            }

            const responseAddProductOrder = await addProductToOrder(product.recordId, quantity, product.price, comment, product.name);

            if (responseAddProductOrder === undefined || !responseAddProductOrder) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error adding product to order.' }));
                throw new Error('Failed to add product to order');
            }

            setOrder(responseAddProductOrder);

            setOrderError(prev => ({ ...prev, orderUpdateError: undefined }));
        } catch (error) {
            setOrderError(prev => ({ ...prev, orderUpdateError: (error as Error).message || 'Unknown error' }));
        } finally {
            setIsOrderLoading(false);
        }
    };

    const deleteProducts = async (linesId: []) => {
        try {
            setIsOrderLoading(true);

            const responseDeleteProductsToOrder = await deleteProductsToOrder(linesId);
            if (responseDeleteProductsToOrder === undefined || !responseDeleteProductsToOrder) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error deleting the product(s) to order.' }));
                throw new Error('Failed to delete product');
            }

            setOrder(responseDeleteProductsToOrder);

            setOrderError(prev => ({ ...prev, orderUpdateError: undefined }));
        } catch (error) {
            setOrderError(prev => ({ ...prev, orderUpdateError: (error as Error).message || 'Unknown error' }));
        } finally {
            setIsOrderLoading(false);
        }
    };

    const updateProducts = async (linesId: []) => {
        try {
            setIsOrderLoading(true);

            const responseUpdateProductsToOrder = await updateProductsToOrder(linesId);
            if (responseUpdateProductsToOrder === undefined || !responseUpdateProductsToOrder) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error modifying the product(s) to order.' }));
                throw new Error('Failed to modify product');
            }

            setOrder(responseUpdateProductsToOrder);

            setOrderError(prev => ({ ...prev, orderUpdateError: undefined }));
        } catch (error) {
            setOrderError(prev => ({ ...prev, orderUpdateError: (error as Error).message || 'Unknown error' }));
        } finally {
            setIsOrderLoading(false);
        }
    };

    const addComments = async (comments: string) => {
        try {
            setIsOrderLoading(true);

            const responseAddCommentsToOrder = await addCommentsToOrder(comments);
            if (responseAddCommentsToOrder === undefined || !responseAddCommentsToOrder) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error adding comments to order.' }));
                throw new Error('Failed to add comments to order');
            }

            const updatedServerOrder = await getOrderById();
            if (updatedServerOrder === undefined || !responseAddCommentsToOrder) {
                setOrderError(prev => ({ ...prev, orderUpdateError: 'There was an error fetching the order.' }));
                throw new Error('Failed to fetch updated order');
            }

            setOrder(updatedServerOrder);

            setOrderError(prev => ({ ...prev, orderUpdateError: undefined }));
        } catch (error) {
            setOrderError(prev => ({ ...prev, orderUpdateError: (error as Error).message || 'Unknown error' }));
        } finally {
            setIsOrderLoading(false);
        }
    };

    return (
        <OrderManagementContext.Provider
            value={{
                addComments,
                addProduct,
                deleteProducts,
                updateProducts,
                createOrderAndAddProductToOrder,
                isOrderLoading,
                order,
                orderError,
                product,
                productQuantity,
                setProduct,
                setProductQuantity
            }}
        >
            {children}
        </OrderManagementContext.Provider>
    );
};
