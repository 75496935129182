import { CartLine, Order, ORDER_STATUS_SUSPENDED } from '../../services';

/**
 * Updates an order with cart lines and recalculates totals.
 * It updates the cartLines from the server while preserving the existing cartLines.
 *
 * This function handles different order statuses, particularly suspended orders,
 * and ensures that cart lines are properly set and totals are recalculated.
 *
 *
 *
 * @param {Order} order - The order object to be updated.
 * @param {Function} setGenericOrder - A function to set generic order properties if needed.
 *                                     It should take an Order as input and return an updated Order.
 * @param {Function} setOrder - A function to update the order state in the parent component.
 *                              It should take the updated Order as input.
 *
 * @throws {Error} Throws an error if the order object is invalid or required properties are missing.
 *
 * @example
 * const order = {
 *   status: 'OPEN',
 *   cart: {
 *     cartLines: [
 *       { productId: 1, quantity: 2, price: 10, taxAmount: 1 }
 *     ]
 *   }
 * };
 * setOrderWithCartLines(order, setGenericOrder, setOrder);
 *
 * @returns {void} This function doesn't return a value but updates the order state via setOrder.
 */
export const setOrderWithCartLines = (order: Order, setGenericOrder: (order: Order) => Order, setOrder: (order: Order) => void): void => {
    let updatedOrder: Order = order;

    if (order.status === ORDER_STATUS_SUSPENDED) {
        updatedOrder = {
            ...order,
            cart: {
                ...order.cart,
                cartLines: order.cart?.cartLines ? [...order.cart.cartLines] : [],
                subtotalSalesAmount: order.cart.subtotalSalesAmount ?? 0,
                totalSalesAmount: order.cart.totalSalesAmount ?? 0
            }
        };
    } else {
        if (!order?.cart || !order?.cart?.cartLines) {
            updatedOrder = setGenericOrder(order);
        }

        const cartLines: CartLine[] = order?.cart?.cartLines ?? [];

        updatedOrder = {
            ...updatedOrder,
            cart: {
                ...updatedOrder?.cart,
                cartLines
            }
        };
    }

    setOrder(updatedOrder);
};
