export const mockFetchedCategories = [
    {
        categoryId: 5637158076,
        name: 'BEER',
        parentCategory: 5637157358,
        displayOrder: 0,
        localizedDescription: 'BEER',
        image: 'Categories/BEER.png'
    },
    {
        categoryId: 5637158077,
        name: 'BEER - DRAFT',
        parentCategory: 5637158076,
        displayOrder: 0,
        localizedDescription: 'BEER - DRAFT',
        image: 'Categories/BEER - DRAFT.png'
    },
    {
        categoryId: 5637158078,
        name: 'BEER - PACKAGED',
        parentCategory: 5637158076,
        displayOrder: 0,
        localizedDescription: 'BEER - PACKAGED',
        image: 'Categories/BEER - PACKAGED.png'
    },
    {
        categoryId: 5637158106,
        name: 'BOTTLED WATER',
        parentCategory: 5637158105,
        displayOrder: 0,
        localizedDescription: 'BOTTLED WATER',
        image: 'Categories/BOTTLED WATER.png'
    },
    {
        categoryId: 5637158107,
        name: 'BREWED BEVERAGE',
        parentCategory: 5637158105,
        displayOrder: 0,
        localizedDescription: 'BREWED BEVERAGE',
        image: 'Categories/BREWED BEVERAGE.png'
    },
    {
        categoryId: 5637157353,
        name: 'DAY OF EVENT MENUS',
        parentCategory: 5637157351,
        displayOrder: 0,
        localizedDescription: '',
        image: 'Categories/DAY OF EVENT MENUS.png'
    },
    {
        categoryId: 5637158079,
        name: 'FOOD',
        parentCategory: 5637157358,
        displayOrder: 0,
        localizedDescription: 'FOOD',
        image: 'Categories/FOOD.png'
    },
    {
        categoryId: 5637158080,
        name: 'FOOD - APPETIZERS',
        parentCategory: 5637158079,
        displayOrder: 0,
        localizedDescription: 'FOOD - APPETIZERS',
        image: 'Categories/FOOD - APPETIZERS.png'
    },
    {
        categoryId: 5637158081,
        name: 'FOOD - BURGERS SAUSAGES DOGS',
        parentCategory: 5637158079,
        displayOrder: 0,
        localizedDescription: 'FOOD - BURGERS SAUSAGES DOGS',
        image: 'Categories/FOOD - BURGERS SAUSAGES DOGS.png'
    },
    {
        categoryId: 5637158082,
        name: 'FOOD - DESSERTS',
        parentCategory: 5637158079,
        displayOrder: 0,
        localizedDescription: 'FOOD - DESSERTS',
        image: 'Categories/FOOD - DESSERTS.png'
    }
];
