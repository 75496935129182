import React from 'react';
import { Header } from '../components';
import { ErrorIcon } from '../icons/error-icon';
import { defaultFontFamily } from '../styles';

interface ErrorPageProps {
    message: string;
    children?: React.ReactNode;
    shouldDisplayHeader?: boolean;
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ message, children, shouldDisplayHeader }) => {
    return (
        <div>
            {shouldDisplayHeader && <Header />}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%',
                    overflow: 'hidden',
                    ...defaultFontFamily
                }}
            >
                <div style={{ textAlign: 'center' }}>
                    <ErrorIcon />
                    <div style={{ margin: '0 24px 0' }}>
                        <span style={{ ...defaultFontFamily, fontWeight: 500, margin: '0 0 18px 0', display: 'block', fontSize: '2em' }}>
                            We&apos;re sorry!
                        </span>
                        <div style={{ ...defaultFontFamily, fontWeight: 400 }}>{message}</div>
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
};
