import { isBrowser } from '.';

/**
 * Sets a cookie with the specified name, value, and optional attributes.
 *
 * @param name - The name of the cookie.
 * @param value - The value of the cookie.
 * @param days - Optional. The number of days until the cookie expires. Default is 7 days.
 * @param path - Optional. The path attribute of the cookie. Default is '/'.
 */
export const setCookie = (name: string, value: string, days: number = 7, path: string = '/') => {
    if (!isBrowser()) {
        return;
    }

    let expires = '';
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${date.toUTCString()}`;
    }

    document.cookie = `${name}=${value || ''}${expires}; path=${path}`;
};
