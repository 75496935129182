import React, { useMemo } from 'react';
import { IntuitDomeIcon } from './intuit-dome-icon';
import { OrderIcon } from './order-icon';
import { useOrderManagement } from '../../context';
import { CartLine } from '../../services';

interface HeaderProps {
    navigateToCart?: () => void;
}

export const Header = ({ navigateToCart }: HeaderProps) => {
    const { order, isOrderLoading } = useOrderManagement();

    const itemsCount = useMemo(() => {
        return order?.cart?.cartLines.reduce((total: number, cartLine: CartLine) => total + cartLine.quantity, 0) ?? 0;
    }, [order?.cart?.cartLines]);

    return (
        <div
            style={{
                alignItems: 'center',
                display: 'flex',
                background: '#EEEEEE',
                margin: '-8px -8px 0px -8px',
                padding: '8px 24px',
                justifyContent: 'space-between'
            }}
        >
            {
                <>
                    <IntuitDomeIcon />
                    {!isOrderLoading && navigateToCart && order && itemsCount > 0 && (
                        <button
                            aria-label={'View order'}
                            style={{
                                alignItems: 'center',
                                display: 'flex',
                                cursor: 'pointer',
                                border: 'none',
                                textDecoration: 'none'
                            }}
                            onClick={() => navigateToCart()}
                        >
                            <OrderIcon />
                            <span
                                style={{
                                    marginLeft: '5px'
                                }}
                            >{`(${itemsCount})`}</span>
                        </button>
                    )}
                </>
            }
        </div>
    );
};
