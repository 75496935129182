import React, { useEffect, useState } from 'react';
import { CUSTOM_EVENT_EMPTY_CATEGORY, URL_IMAGES } from '../../components/constants';
import { Product } from '../../services';
import { useCategory } from '../../context';
import { useFetchProducts } from '../../hooks';
import { transformUrl } from '../../utils';
import ServiceFeeDisclaimer from '../../components/service-fee-disclaimer';
import { defaultFontFamily } from '../../styles';
import { ScrollMenu } from 'react-horizontal-scrolling-menu';
import { DividerIcon } from './divider-icon';
import { DisabledRemoveIcon } from './disabled-remove-icon';
//import { RemoveIcon } from './remove-icon';
import { AddIcon } from './add-icon';

type CategoryProductsPageProps = {
    categoryName: string;
    categoryId: number;
    navigateToItem: (product: Product) => void;
};

export const CategoryProductsPage = ({ categoryId, categoryName, navigateToItem }: CategoryProductsPageProps) => {
    const { filteredSubCategories, handleSubCategorySelection } = useCategory();
    const [subCategory, setSubCategory] = useState<number>(0);
    const mainCategoryId = !filteredSubCategories.length && subCategory === 0 ? categoryId : subCategory;
    const defaultSubCategoryId = filteredSubCategories.length && subCategory === 0 ? filteredSubCategories[0].categoryId : mainCategoryId;
    const { products, error: productError, isLoading: isProductLoading } = useFetchProducts(defaultSubCategoryId);

    const handleCategoryClick = (subCategoryId: number) => {
        setSubCategory(subCategoryId);
    };

    const handleError = (e: any) => {
        e.target.src =
            'https://images-us-prod.cms.commerce.dynamics.com/cms/api/skhrmstldd/imageFileData/search?fileName=/Products/Z0209_000_001.png';
    };

    useEffect(() => {
        handleSubCategorySelection(categoryId, subCategory);
    }, [subCategory]);

    const renderProductsList = () =>
        products?.map((product: Product) => (
            <li id={`product-${product.productId}`} key={product.productId} style={{ marginBottom: '16px' }}>
                <div style={{ display: 'flex', alignItems: 'flex-start', gap: '12px' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '4px', marginBottom: '30px' }}>
                        <p style={{ fontWeight: 'bold', margin: '0px', marginBottom: '4px', lineHeight: '24px' }}>
                            {!!product.name ? product.name : '-'}
                        </p>
                        <p style={{ fontSize: '14px', margin: '0px', marginBottom: '4px', lineHeight: '20px' }}>
                            {!!product.description ? product.description : '-'}
                        </p>
                        <button
                            onClick={() => {
                                navigateToItem(product);
                            }}
                            style={{
                                background: 'white',
                                border: 'none',
                                padding: '0',
                                marginRight: 'auto'
                            }}
                        >
                            <p
                                style={{
                                    ...defaultFontFamily,
                                    fontWeight: 700,
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    textDecoration: 'underline',
                                    margin: '0'
                                }}
                            >
                                View more
                            </p>
                        </button>
                    </div>
                    <div style={{ marginLeft: 'auto' }}>
                        <img
                            src={transformUrl(`${URL_IMAGES}${product.primaryImageUrl}`)}
                            alt={product.description}
                            style={{ width: '88px', height: 'auto' }}
                            onError={e => handleError(e)}
                        />
                    </div>
                </div>
                <div style={{ display: 'flex' }}>
                    <p style={{ fontWeight: 'bold', fontSize: '16px', margin: 'auto 0' }}>${product.price.toFixed(2)}</p>
                    <div style={{ display: 'flex', gap: '16px', marginLeft: 'auto' }}>
                        <button
                            style={{
                                border: 'none',
                                background: 'transparent',
                                cursor: 'pointer',
                                margin: 0,
                                padding: 0
                            }}
                            aria-label='Decrease Item'
                        >
                            <DisabledRemoveIcon />
                        </button>
                        <p style={{ fontWeight: 600, fontSize: '32px', margin: 0 }}>0</p>
                        <button
                            style={{
                                border: 'none',
                                background: 'transparent',
                                cursor: 'pointer',
                                margin: 0,
                                padding: 0
                            }}
                            aria-label='Increase Item'
                        >
                            <AddIcon />
                        </button>
                    </div>
                </div>
                <DividerIcon />
            </li>
        ));

    if (isProductLoading) {
        return <img style={{ position: 'absolute', top: '40%', left: '32%' }} src='../../../spinner.gif'></img>;
    }

    return (
        <>
            <section>
                <h2 className='header-title'>{categoryName ?? '-'}</h2>
                {filteredSubCategories.length > 1 ? (
                    <ScrollMenu>
                        {filteredSubCategories.map(({ name, categoryId, selected }) => (
                            <button
                                key={categoryId}
                                onClick={() => {
                                    handleCategoryClick(categoryId);
                                }}
                                style={{ background: 'white', border: 'none', margin: '20px 10px 16px 10px' }}
                            >
                                <p
                                    style={{
                                        ...defaultFontFamily,
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        whiteSpace: 'nowrap',
                                        margin: '0',
                                        marginBottom: '10px'
                                    }}
                                    className={selected ? 'selected' : ''}
                                >
                                    {name}
                                </p>
                            </button>
                        ))}
                    </ScrollMenu>
                ) : null}
                {products?.length ? <ul style={{ listStyleType: 'none', padding: '8px 24px' }}>{renderProductsList()}</ul> : null}
                {productError === CUSTOM_EVENT_EMPTY_CATEGORY ? (
                    <div id={CUSTOM_EVENT_EMPTY_CATEGORY} style={{ margin: '24px 20px', textAlign: 'center' }}>
                        <p>There are no products in this category</p>
                    </div>
                ) : null}
                {productError && productError !== CUSTOM_EVENT_EMPTY_CATEGORY ? (
                    <div style={{ margin: '24px 20px', textAlign: 'center' }}>
                        <p>There was an error loading your products . Please try again.</p>
                    </div>
                ) : null}
            </section>
            <ServiceFeeDisclaimer />
        </>
    );
};
