import React from 'react';
import { CategoriesPage, CartDetail, CartUpdate, CategoryProductsPage, ProductDetailPage } from '../index';
import { checkRequiredCookies, getCookie, isBrowser, hasErrorProperties, scrollTo, getLocationHash } from '../../utils';
import { useFetchProducts, useHashChangeObserver } from '../../hooks';
import { useCategory, useNavigation, useOrderManagement } from '../../context';
import {
    COOKIE_CLOSE_WEBVIEW_TO_PREVIOUS_URL,
    PAGE_CART_DETAIL,
    PAGE_CART_UPDATE,
    PAGE_CATEGORY,
    PAGE_PRODUCT_DETAIL,
    PAGE_ITEM_LIST,
    requiredCookies,
    ERROR_MESSAGE_COOKIES,
    ERROR_MESSAGE_CATEGORY_ERROR,
    ERROR_MESSAGE_ORDER_ERROR
} from '../../components/constants';
import { Header } from '../../components';
import { ErrorPage } from '../error-page';
import { defaultFontFamily } from '../../styles';

export const RouteSwitcher = () => {
    const { categories, filteredCategories, mainCategoryId, error: categoryError, isCategoryLoading } = useCategory();
    const { orderError, isOrderLoading, setProduct } = useOrderManagement();
    const { navigateToItem, navigateToCategoryProductsPage, navigateToCart, navigateToCategoryPage } = useNavigation();
    const { products } = useFetchProducts(mainCategoryId);

    const closeWebViewToPreviousUrl = getCookie(COOKIE_CLOSE_WEBVIEW_TO_PREVIOUS_URL);

    //useVisibilityObserver(); //remove the suspended call when page is closed
    useHashChangeObserver();

    const withTemplate = (content: JSX.Element): JSX.Element => (
        <div style={{ ...defaultFontFamily }}>
            <Header navigateToCart={navigateToCart} />
            {content}
        </div>
    );

    const renderPage = () => {
        scrollTo();
        const currentHash = getLocationHash().split('/');
        switch (currentHash[0]) {
            case PAGE_CATEGORY:
            case '':
                const mainCategory = categories.find(({ categoryId }) => categoryId === mainCategoryId);
                return (
                    <div style={{ margin: '0px -8px 24px -8px' }}>
                        <CategoriesPage
                            categories={filteredCategories}
                            categoryId={mainCategoryId ?? undefined}
                            navigateToCategoryProductsPage={navigateToCategoryProductsPage}
                            navigateToCategoryPage={navigateToCategoryPage}
                            cookieCategory={mainCategory}
                        />
                    </div>
                );
            case PAGE_ITEM_LIST:
                const categoryId = parseInt(currentHash[1], 10);
                return (
                    <div style={{ margin: '0px -8px 24px -8px' }}>
                        <CategoryProductsPage
                            categoryName={categories.find(c => c.categoryId === categoryId)?.name ?? '-'}
                            categoryId={categoryId}
                            navigateToItem={navigateToItem}
                        />
                    </div>
                );
            case PAGE_PRODUCT_DETAIL:
                const product = products ? products.find(({ productId }) => productId === currentHash[1]) : undefined;
                setProduct(product);
                return (
                    <div style={{ margin: '24px 20px' }}>
                        <ProductDetailPage
                            navigateToCategoryProductsPage={navigateToCategoryProductsPage}
                            navigateToCart={navigateToCart}
                        />
                    </div>
                );
            case PAGE_CART_DETAIL:
                return (
                    <div style={{ margin: '24px 20px' }}>
                        <CartDetail />
                    </div>
                );
            case PAGE_CART_UPDATE:
                return (
                    <div style={{ margin: '24px 20px' }}>
                        <CartUpdate />
                    </div>
                );
            default:
                return <ErrorPage message={ERROR_MESSAGE_CATEGORY_ERROR} />;
        }
    };

    try {
        checkRequiredCookies(requiredCookies);
    } catch (error) {
        return <ErrorPage shouldDisplayHeader message={ERROR_MESSAGE_COOKIES} />;
    }

    if (isOrderLoading || isCategoryLoading) {
        return <img className='spinner'></img>;
    }

    if (orderError && hasErrorProperties(orderError) && orderError.userAuthenticationError) {
        return (
            <ErrorPage message={orderError.userAuthenticationError}>
                <button
                    onClick={() => {
                        if (closeWebViewToPreviousUrl) {
                            window.location.href = closeWebViewToPreviousUrl;
                        }
                    }}
                    style={{
                        fontSize: '16px',
                        background: 'black',
                        color: 'white',
                        cursor: 'pointer',
                        padding: '10px 24px',
                        textTransform: 'uppercase',
                        borderRadius: '20px 0 20px 0',
                        marginTop: '16px',
                        textDecoration: 'none',
                        width: '100%',
                        textAlign: 'center'
                    }}
                >
                    Close Menu
                </button>
            </ErrorPage>
        );
    }

    if (orderError && hasErrorProperties(orderError) && (orderError.orderFetchError || orderError.orderUpdateError) && isBrowser()) {
        return (
            <ErrorPage shouldDisplayHeader message={ERROR_MESSAGE_ORDER_ERROR}>
                {orderError.orderFetchError && <p>{orderError.orderFetchError}</p>}
                {orderError.orderUpdateError && <p>{orderError.orderUpdateError}</p>}
            </ErrorPage>
        );
    }

    if (categoryError) {
        return <ErrorPage shouldDisplayHeader message={ERROR_MESSAGE_CATEGORY_ERROR} />;
    }

    return withTemplate(renderPage());
};
