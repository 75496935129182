import { AppError } from '../context/types';

/**
 * @description Checks if the provided error object has any truthy properties.
 * This function iterates over all properties of the `errorObject` and returns `true` if at least one property has a truthy value.
 * If the `errorObject` is null or if all properties are falsy, it returns `false`.
 * @param {AppError | null} errorObject - The error object to check for truthy properties. Can be null.
 * @returns {boolean} `true` if at least one property of the error object is truthy, otherwise `false`.
 */
export const hasErrorProperties = (errorObject: AppError | null): boolean => {
    if (!errorObject) {
        return false;
    }
    return Object.values(errorObject).some(value => !!value);
};
