import { COOKIE_X_API_KEY, COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN } from '../../components/constants';
import { getCookie, setCookie } from '../../utils';
import { FetchError } from '../errors';

export const CONTENT_TYPE_JSON = 'application/json';
export const BEARER_PREFIX = 'Bearer ';

/**
 * Returns the base headers for the fetch request.
 *
 * - If COOKIE_ACCESS_TOKEN is present, includes xApiKey.
 * - If COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN, and COOKIE_X_API_KEY are present, includes xApiKey.
 * - If COOKIE_ACCESS_TOKEN is not set, and COOKIE_REFRESH_TOKEN and COOKIE_X_API_KEY are present, includes Authorization header.
 * - If COOKIE_ACCESS_TOKEN, COOKIE_REFRESH_TOKEN, and COOKIE_X_API_KEY are not present, throws an error.
 *
 * @returns {{ headers: Record<string, string>; shouldRefreshToken: boolean }} - The base headers and whether the token should be refreshed.
 * @throws {Error} - Throws an error if the required cookies are missing.
 */
export const getBaseHeaders = (): { headers: Record<string, string>; shouldRefreshToken: boolean } => {
    const accessToken = getCookie(COOKIE_ACCESS_TOKEN) ?? '';
    const refreshToken = getCookie(COOKIE_REFRESH_TOKEN) ?? '';
    const xApiKey = getCookie(COOKIE_X_API_KEY) ?? '';
    const shouldRefreshToken = !xApiKey && !!accessToken && !!refreshToken;

    if (!accessToken && !refreshToken && !xApiKey) {
        throw new Error('Required cookies are missing');
    }

    const headers: Record<string, string> = {
        'Content-Type': CONTENT_TYPE_JSON,
        ...(shouldRefreshToken
            ? {
                  Authorization: `${BEARER_PREFIX}${accessToken}`
              }
            : { 'X-API-KEY': xApiKey })
    };

    return { headers, shouldRefreshToken };
};

export type RefreshTokenResponse = {
    accessToken: string;
    refreshToken: string;
};

/**
 * Refreshes the access token using the provided refresh token URL and tokens.
 * @param {string} refreshTokenUrl - The URL to refresh the token.
 * @param {string} expiredAccessToken - The expired access token.
 * @param {string} refreshToken - The refresh token.
 * @returns {Promise<RefreshTokenResponse>} - The new access and refresh tokens.
 * @throws {FetchError} - Throws if the refresh token request fails.
 */
export const refreshAccessToken = async (
    refreshTokenUrl: string,
    expiredAccessToken: string,
    refreshToken: string
): Promise<RefreshTokenResponse> => {
    const response = await fetch(refreshTokenUrl, {
        method: 'PUT',
        headers: {
            'Content-Type': CONTENT_TYPE_JSON
        },
        body: JSON.stringify({ expiredAccessToken, refreshToken })
    });

    if (!response.ok) {
        throw new FetchError('Failed to refresh token', response.status);
    }

    const tokens = await response.json();
    setCookie(COOKIE_ACCESS_TOKEN, tokens.accessToken);
    setCookie(COOKIE_REFRESH_TOKEN, tokens.refreshToken); // Assuming you have a similar function for refresh tokens
    return { accessToken: tokens.accessToken, refreshToken: tokens.refreshToken };
};

export const handleFetchError = (error: unknown): never => {
    if (error instanceof Error) {
        throw new FetchError(error.message, (error as FetchError).status);
    } else {
        throw new FetchError('Unknown error');
    }
};
