import { Cart, Order } from '../services';

const defaultCart: Cart = {
    attributeValues: [],
    cartLines: [],
    cartVersion: 0,
    id: '',
    amountDue: 0,
    amountPaid: 0,
    isTaxIncludedInPrice: false,
    beginDateTime: '',
    cartTypeValue: 0,
    channelId: 0,
    chargeAmount: 0,
    serviceChargeAmount: 0,
    comment: '',
    customerId: '',
    customerOrderModeValue: 0,
    discountAmount: 0,
    discountAmountWithoutTax: 0,
    discountCodes: [],
    isSuspended: false,
    modifiedDateTime: '',
    staffId: '',
    subtotalAmount: 0,
    subtotalAmountWithoutTax: 0,
    netPrice: 0,
    subtotalSalesAmount: 0,
    taxAmount: 0,
    taxOnCancellationCharge: 0,
    taxOnShippingCharge: 0,
    taxOnNonShippingCharges: 0,
    terminalId: '',
    totalAmount: 0,
    totalSalesAmount: 0,
    totalReturnAmount: 0,
    totalCarryoutSalesAmount: 0,
    totalCustomerOrderSalesAmount: 0,
    totalManualDiscountAmount: 0,
    totalManualDiscountPercentage: 0,
    warehouseId: '',
    version: 0,
    totalItems: 0,
    tenderLines: []
};

/**
 * @description Orders with ORDER_STATUS_NEW
 * do not include a cart property
 * so we use this function to add missing properties.
 * Specially `cart.cartLines`
 *
 * @example
 * const orderById = {
 *   cart: { cartLines: [], cartVersion: 1, id: '123', amountDue: 100 },
 *   // Other order properties
 * };
 *
 * const newOrder = setGenericOrder(orderById);
 * console.log(newOrder);
 */
export const setGenericOrder = (orderById: Order): Order => {
    return {
        ...orderById,
        cart: { ...defaultCart }
    };
};
