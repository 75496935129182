import { getCookie } from '../utils';

export const API_URL = getCookie('commerceApiUrl');

export const ORDER_STATUS_NEW = 'NEW';
export const ORDER_STATUS_OPEN = 'OPEN';
export const ORDER_STATUS_SUSPENDED = 'SUSPENDED';

export const ORDER_MENU_TYPE_PRE = 'PRE';
export const ORDER_MENU_TYPE_DOE = 'DOE';

export type MenuType = typeof ORDER_MENU_TYPE_PRE | typeof ORDER_MENU_TYPE_DOE | undefined;
